export const Mail_Vars = {
    fb_link: "https://www.facebook.com/",
    tw_link: "https://twitter.com/",
    sitename: "Dales & Peaks Estate Agents",
    contact_email: "clapham@petergreatorex.com",
    company_phone: "0123 485 4050",
    available_time: "9am - 5pm ,",
    available_days: " Monday - Friday",
    address: "6 Clapham High Street, Clapham, London SW4 7UT",
    primary_color: "#C7A900"
}

export const Site_Vars = {
    default_currency: "£"
}

export const getWhatsAppURL = number => {
    if (!number) return null
    // remove everything except numbers
    const formatedNumber = number?.replace(/\D/g, "")
    return `https://api.whatsapp.com/send?phone=${formatedNumber}`
}

export const AppendScript = (url, id, callback) => {
    if (document.getElementById(id) === null) {
      var e = document.createElement("script");
      e.src = url;
      e.id = id;
      e.async = true;
      e.defer = true;
      e.type = "text/javascript";
      e.addEventListener('load', callback(true));
      document.getElementsByTagName("body")[0].appendChild(e);
    }else{
        callback(false)
    }
}